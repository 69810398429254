import { ServiceAlerts } from "@/services/ServiceAlerts";
import { ServiceMail } from "@/services/ServiceMail";
import nomenclatoare from "@/store/nomenclatoare";
import user from "@/store/user";
import { TEmailSubject } from "@/types/TEmailSubject";
import { TUser } from "@/types/TUser";
import { Options, Vue } from "vue-class-component";
import {getModule} from "vuex-module-decorators";


@Options({
    name: "AdminSendNotification",
    components: {}
})
export default class AdminSendNotification extends Vue {
    public userStore = getModule(user); 
    public storeNomenclatoare = getModule(nomenclatoare);
    public subject='';
    
    get user(): TUser {
        return this.userStore.user;
    }

    public onSubmit() {
        const vueInst = this;
        console.log(this.user);
        ServiceAlerts.sendNotification(vueInst.subject).then(responce => {
            if (responce.status == 'success') {
                vueInst.$q.notify({
                    color: 'blue',
                    textColor: 'white',
                    type: 'info',
                    icon: 'info',
                    position: 'top',
                    timeout: 1000,
                    message: this.$t('message.notification_sent') 
                })
                void ServiceAlerts.sendNotificationEmail(vueInst.subject).catch(error => {
                    console.error("Error sending email:", error);
                });
                vueInst.subject = '';
            } else {
                vueInst.subject = '';
            }
        });
    }
}